export const siteLogo = {
    logo: "assets/images/aliciamoffordlogo.png",  // Add your site logo here
    alt: "Alicia Mofford"  // Add your site name here
};
export const socialLink = {
    facebook: "https://www.facebook.com/Alicia-Mofford-281092923737946",
    twitter: "https://twitter.com/AuthorAMofford",
    instagram: "https://www.instagram.com/authoraliciamofford",
    pintrest: "https://www.pinterest.com/AuthorAliciaMofford/",
    goodreads: "https://www.goodreads.com/author/show/22215820.Alicia_Mofford",
    amazon: "https://www.amazon.com/Alicia-Mofford/e/B09R3NC59R?ref=sr_ntt_srch_lnk_1&qid=1645419554&sr=8-1",
};
export const mailchimpLink = {
    link: (process.env.REACT_APP_MAILCHAIMP) // Add your mailchimp list link here
};
export const contactFormAction = {
    link: (process.env.REACT_APP_CONTACTFORM)  // Add for link here from https://formspree.io
}
export const contactMap = {
    link: (process.env.REACT_APP_CONTACTMAP) // Add google map link from here: https://www.embedgooglemap.net/
}
