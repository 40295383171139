import React from "react";
import Author from "../author";
import Contact from "../contact";
import Cta from "../cta";
import Footer from "../global/footer";
import Header from "../global/header";
import Herov2 from "../herov2";
import Booksv3 from "../booksv3";


const Version02 = ({header, footer}) => {
  const {menu} = footer;
  return (
    <>
      <Header header={header} />
      <Herov2 isBg="yes"/>

      <Booksv3 isBg=""/>

      <Cta isBg=""/>
      <Author isBg="yes"/>



      <Contact isBg=""/>
      <Footer isBg="yes" menu={menu} />
    </>
  );
};

export default Version02;
