/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import data from "../data/author.json";

const Authorv3 = ({isBg}) => {
  const { authorv3 } = data;
  return (
    <section id="home" className={`section-padding authorv3 ${
      isBg === "yes" ? "bg-one" : ""
    }`}
    >
      <div className="container">
        <div className="row">
          <div
            className="col-lg-6"
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-delay="200"
          >
            <div className="author-image">
              <img className="img-fluid" src={authorv3.image} alt="" />
            </div>
          </div>
          <div
            className="col-lg-6"
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-delay="200"
          >
            <div className="authorv3__content">
              <div className="authorv3__content--badge">
                {authorv3.subtitle}
              </div>
              <h3 className="display-5 mb-0">{authorv3.title}</h3>
              <p className="m-30px-b text-muted fs-5">{authorv3.text}</p>
              <ul className="social-icon mt-0 mb-0">
                {authorv3.social?.map((data, i) => (
                  <li key={i}>
                    {data.link === "" ? (
                      ""
                    ) : (
                      <a href={data.link}>
                        <img
                          className="img-fluid"
                          src={data.icon}
                          alt="icon"
                          width="25"
                          height="25"
                        />
                      </a>
                    )}
                  </li>
                ))}
              </ul>
              <ul className="author__content__desc m-30px-b">
                {authorv3.achievement?.map((data, i) => (
                  <li key={i}>
                    <div className="authorv3__check">
                    <img
                      className="img-fluid"
                      src={data.icon}
                      alt="icon"
                      width="25"
                      height="25"
                    />
                  <h5 className="m-10px-b ">{data.achieves}</h5>
                  </div>
                    <p className="m-10px-b">{data.description}</p>

                  </li>
                ))}

              </ul>
              <a
                href="https://aliciamofford.square.site/"
                className="smooth button button__primary me-3"
                target="_blank"
                rel="noreferrer"
              >
                <span>{authorv3.buyBtn}</span>
              </a>
            </div>

          </div>

        </div>
      </div>
    </section>
  );
};

export default Authorv3;
