import React, { useState, useEffect } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import data from "../data/cta.json";
import { mailchimpLink } from "../global";

// DownloadButton component
function DownloadButton() {
  return (
    <a href="https://aliciamofford.com/assets/Downlaods/Interior-Educational_Addendum.pdf" download>
      <button className="download-button button__primary">Download Now</button>
    </a>
  );
}

// SubscribeForm component
function SubscribeForm({ status, message, onValidated }) {
  let email;
  let full_name;

  const submit = (e) => {
    e.preventDefault();
    onValidated({
      EMAIL: email.value,
      FNAME: full_name.value,
    });
  };

  return (
    <>
      <input
        ref={(node) => (email = node)}
        type="email"
        placeholder="Your email"
      />
      <input
        ref={(node) => (full_name = node)}
        type="full_name"
        placeholder="Name"
      />

      <br />
      <button
        className="w-100 button button__primary align-items-center aos-init"
        onClick={submit}
      >
        <span>Submit</span>
      </button>

      <div className="message col m-10px-t">
        {status === "sending" && (
          <div className=" alert alert-warning">sending...</div>
        )}
        {status === "error" && (
          <div
            className="alert alert-danger"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "empty" && (
          <div
            className="alert alert-danger"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "duplicate" && (
          <div
            className="alert alert-danger"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "button" && (
          <div
            className="alert alert-danger"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "success" && (
          <>
            <div className="alert alert-success" dangerouslySetInnerHTML={{ __html: message }}>
              {/* Success message here... */}
            </div>
            <DownloadButton />
          </>
        )}
      </div>
    </>
  );
}

// Cta component
const Cta = ({ isBg }) => {
  const { cta } = data;
  return (
    <section
      className={`cta section-padding ${isBg === "yes" ? "bg-one" : ""}`}
      id="cta"
      style={{ backgroundImage: `url("${cta.backgroundImage}")` }}
    >
      <div className="container">
        <div className="row justify-content-center cta__inner bg-one">
          <div className="section-title-center mb-0 text-center justify-content-center">
            <span>{cta.subtitle}</span>
            <h2 className="display-6">{cta.title}</h2><br/><br/>
          </div>

          {/* Image Column */}
          <div className="col-lg-6">
            <img src={cta.image} className="cta__image" alt="Second Edition Educational Content"/>
          </div>

          {/* Form Column */}
          <div className="col-lg-6">
            <div className="cta-form-box">
              <MailchimpSubscribe
                url={mailchimpLink.link}
                render={({ subscribe, status, message }) => (
                  <SubscribeForm
                    status={status}
                    message={message}
                    onValidated={(formData) => subscribe(formData)}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cta;
