import React from "react";
import data from "../data/cta.json";

const CtaV3 = ({isBg}) => {
  const { ctav3 } = data;
  return (
    // <!-- ========== CTA v3 section start ========== -->
      <section id="home" className={`section-padding ctav3 bg-one p-80px-t   ${isBg === "yes" ? "bg-one": "" }`} style={{backgroundImage: `url(${ctav3.backgroundImage})`}}>

      <div className="container">
        <div className="row ctav3__content">
          <div className="col-md-6 col-lg-6 mb-4 mb-lg-0 m-15px-t ">
            <div
              key="1"
              className="ctav3__awards"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay={(1 + 2) * 50}
            >
            <h3 className="m-15px-t m-25px-b">
              {ctav3.title1} <br />
              {ctav3.title2}
            </h3>

            <a
              href="https://aliciamofford.square.site/"
              className="button button__body"
              target="_blank"
              rel="noreferrer"
            >
              <span>{ctav3.buyBtn}</span>
            </a>

            <div className="ctav3__content__icon">
              <i className="icofont-read-book"></i>
            </div>
          </div>
          </div>
          <div className="col-md-6 col-lg-6 mb-4 mb-lg-0 m-15px-t ">
            {ctav3.heroItem?.map((data, i) => (
              <div
                key={i}
                className="ctav3__awards"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay={(i + 2) * 50}
              >

              <div className="m-25px-b m-15px-t ctav6__check">
                  <img
                      className="img-fluid"
                      src={data.icon}
                      alt="icon"
                      width="70px"
                      height="70px"
                    />
                  <h3 className="m-10px-t m-10px-b">{data.title}</h3>
              </div>





              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
    // <!-- ========== CTA v3 section end ========== -->
  );
};

export default CtaV3;
