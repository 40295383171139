import React from "react";
import data from "../data/books.json";
import Testimonial from "./testimonial";
import ChapterPreviewV2 from "./chapter-preview-v2";

const Booksv3 = ({ isBg }) => {
  const { booksv3 } = data;
  const books = booksv3.booksItem;

  return (
    <section
      id="books"
      className={`books section-padding ${isBg === "yes" ? "bg-one" : ""}`}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
            <div className="section-title-center text-center">
              <span>{booksv3.title}</span>
              <h2 className="display-6">{booksv3.subtitle}</h2>
              <div className="section-divider divider-traingle"></div>
            </div>
          </div>
        </div>

        <div className="row">
          {books.map((book, i) => (
            <div
              key={book.id}
              className="col-md-6 col-lg-3 mb-4"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay={(i + 3) * 50}
            >
            <a
              href={book.link}
              target="_blank"
              rel="noreferrer"
            >
              <div className="books__book h-100 translateEffect1">
                <img className="img-fluid" src={book.image} alt={`Book ${i + 1}`} />
                <p className="books__book__subtitle">{book.subtitle}</p>
                <h3 className="books__book__title">{book.title}</h3>
                <div className="d-flex justify-content-between">
                  <span>{book.price}</span>
                </div>
              </div>
                </a>
            </div>
          ))}
        </div>

        <div
          key="1"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay={(books.length + 3) * 50}
        >
          <div className="booksv1__content booksv1__description m-30px-t m-30px-b">
            <h2 className="m-30px-b m-30px-t">{booksv3.name}</h2>
            <p className="m-30px-b m-30px-t">{booksv3.description}</p>
            <div className="button-wrapper">
              <a
                href="https://aliciamofford.square.site/"
                className="button button__body vertical-center"
                target="_blank"
                rel="noreferrer"
              >
                <span>{booksv3.buyBtn}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <ChapterPreviewV2 isBg="yes" />
      <Testimonial isBg="" />
    </section>
  );
};

export default Booksv3;
