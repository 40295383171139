import React from "react";
import data from "../data/cta.json";

const Ctav6 = ({isBg}) => {
  const {ctav6 } = data;

  return (
    // <!-- ========== CTA v5 section start ========== -->
    <section id="home" className={`${isBg === "yes" ? "bg-one": "" }`} style={{backgroundImage: `url(${ctav6.backgroundImage})`}}>
    <div className="container">
        <div className="ctav6__content m-90px-t">
          <div className="row justify-content-center">
            {ctav6.heroItem?.map((data, i) => (
              <div
                key={i}
                className="col-md-6 col-lg-4 mb-4 mb-lg-0 "
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay={(i + 2) * 50}
              >

              <div className="m-10px-b m-10px-t ctav6__check">
                  <img
                      className="img-fluid"
                      src={data.icon}
                      alt="icon"
                      width="40px"
                      height="40px"
                    />
                  <h5 className="m-10px-t m-10px-b">{data.title}</h5>
              </div>





              </div>
            ))}
          </div>

      </div>

  </div>
    </section>
    // <!-- ========== CTA v5 section end ========== -->
  );
};

export default Ctav6;
