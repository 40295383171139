import React from "react";

import { BiPlayCircle } from "react-icons/bi";
import data from "../data/hero.json";
import Ctav5 from "./ctav5";
import Ctav3 from "./ctav3";
import Ctav6 from "./ctav6";
import Authorv3 from "./authorv3";
import Achievev2 from "./achievev2";
import Booksv1 from "./booksv1";


const Herov2 = ({ isBg }) => {
  const { AliciaMoffordHero } = data;

  return (
    <section
      id="home"
      className={`hero hero__padding overflow-hidden position-relative ${isBg === "yes" ? "bg-one" : ""}`}
    >
      <div className="circle x1">
        <img className="img-fluid" src={AliciaMoffordHero.circleImage1} alt="" /></div>
      <div className="circle x2"></div>
      <div className="circle x3"></div>
      <div className="circle x4"></div>
      <div className="circle x5"></div>
      <div className="circle x6">
        <img className="img-fluid" width="400" src={AliciaMoffordHero.circleImage6} alt="" />
      </div>

      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-7 col-lg-6 m-0px-b md-m-40px-b">
            <div className="hero__content position-relative">
              <div className="badge-text mb-2 text-uppercase">
                {AliciaMoffordHero.subtitle}
              </div>
              <h1 className="display-4 mb-4 text-capitalize">{AliciaMoffordHero.title}</h1>
              <p className="text-muted mb-5 fs-5">{AliciaMoffordHero.description}</p>

              <div>
                <a
                  href="https://aliciamofford.square.site/"
                  className="smooth button button__primary me-3"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>{AliciaMoffordHero.buyBtn}</span>
                </a>
                <a
                  href="https://www.youtube.com/watch?v=lCRnBG-8ACU"
                  className="glightbox3 btn__secondary"

                >
                  <BiPlayCircle /> {AliciaMoffordHero.aboutBtn}
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-5 offset-lg-1 text-center ">

            <div className="hero__images3">
              <div className="hero__educational-header">
                <p className="hero__educational-text">New Educational Content Added!</p>
              </div>
              <img
                width="400"
                className="img-fluid"
                src={AliciaMoffordHero.image}
                alt=""
              />

              <div className="hero__images--badge">
                <span>
                  <span className="hero__images--badge--text1">
                    {AliciaMoffordHero.tagTitle}
                  </span>
                  <span className="hero__images--badge--text2">
                    {AliciaMoffordHero.tagText}
                  </span>
                </span>
              </div>
            </div>
          </div>

      </div>

      </div>

      <Ctav6 isBg="yes"/>

      <Authorv3 isBg=""/>
      <Achievev2 isBg="yes"/>

      <Ctav5 isBg=""/>
      <Booksv1 isBg=""/>
      <Ctav3 isBg="" />
  </section>
  );
};

export default Herov2;
